import type { SeoFragment } from '~/model/api.ts'
import type { MetaDescriptor } from '@remix-run/node'
import type { getPageState } from '~/utils/page-state.ts'
import { getI18n } from 'react-i18next'

export type MetaProps = {
  seo: SeoFragment | undefined | null
  pageState: Awaited<ReturnType<typeof getPageState>>
  localizations?: string[]
}

export async function validateSeo(
  seo: SeoFragment | undefined | null,
  request: Request,
): Promise<SeoFragment> {
  const i18n = getI18n()
  return {
    ...(seo || {}),
    metaTitle: seo?.metaTitle ?? i18n.t('default_meta_title'),
    metaDescription: seo?.metaDescription ?? i18n.t('default_meta_description'),
  }
}

export function generateMeta(props: MetaProps) {
  const { locale, path, canonical } = props.pageState
  let pathName = path

  if (pathName.startsWith('/')) {
    pathName = path.slice(1)
  }

  let metaTags: MetaDescriptor[] = [
    { title: props.seo ? `${props.seo.metaTitle} | ROUVY` : '404 not found' },
    {
      name: 'description',
      content: props.seo ? props.seo.metaDescription : '',
    },
    {
      name: 'og:description',
      content: props.seo ? props.seo.metaDescription : '',
    },
    {
      property: 'og:title',
      content: props.seo ? `${props.seo.metaTitle} | ROUVY` : '',
    },
    {
      property: 'robots',
      content: `${props.seo?.noIndex ? 'noindex' : 'index'},${
        props.seo?.noFollow ? 'nofollow' : 'follow'
      }`,
    },
    {
      property: 'googlebot',
      content: `${props.seo?.noIndex ? 'noindex' : 'index'},${
        props.seo?.noFollow ? 'nofollow' : 'follow'
      }`,
    },
    {
      tagName: 'link',
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon.ico',
    },
    {
      tagName: 'link',
      rel: 'apple-touch-icon',
      type: 'image/x-icon',
      href: '/favicon.ico',
    },
    {
      tagName: 'link',
      rel: 'shortcut icon',
      type: 'image/x-icon',
      href: '/favicon.ico',
    },
    {
      property: 'og:site_name',
      content: 'ROUVY',
    },
    {
      property: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
    {
      property: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      property: 'twitter:site',
      content: '@gorouvy',
    },
    {
      property: 'twitter:creator',
      content: '@gorouvy',
    },
    {
      property: 'author',
      content: 'VirtualTraining s.r.o',
    },
    {
      property: 'copyright',
      content: `Copyright © ${new Date().getFullYear()}, VirtualTraining s.r.o. All rights reserved.`,
    },
  ]

  if (props.seo?.shareImage?.data?.attributes) {
    metaTags = metaTags.concat([
      {
        property: 'og:image',
        content: props.seo?.shareImage?.data?.attributes?.url,
      },
      {
        property: 'og:image:alt',
        content:
          props.seo?.shareImage?.data?.attributes?.alternativeText ?? 'Rouvy',
      },
      {
        property: 'og:image:width',
        content: props.seo?.shareImage?.data?.attributes?.width,
      },
      {
        property: 'og:image:height',
        content: props.seo?.shareImage?.data?.attributes?.height,
      },
    ])
  } else {
    metaTags = metaTags.concat([
      {
        property: 'og:image',
        content: '/images/og.png',
      },
      {
        property: 'og:image:alt',
        content: 'Rouvy',
      },
      {
        property: 'og:image:width',
        content: 1200,
      },
      {
        property: 'og:image:height',
        content: 675,
      },
    ])
  }

  if (canonical || !['en'].concat(props.localizations ?? []).includes(locale)) {
    metaTags.push({
      tagName: 'link',
      rel: 'canonical',
      href: `${ENV.BASE_URL}/${pathName}`,
    })
  }

  metaTags.push({
    tagName: 'link',
    rel: 'alternate',
    hrefLang: 'en',
    href: `${ENV.BASE_URL}/${pathName}`,
  })
  props?.localizations
    ?.filter(a => a !== 'en')
    .forEach(a =>
      metaTags.push({
        tagName: 'link',
        rel: 'alternate',
        hrefLang: a,
        href: `${ENV.BASE_URL}/${a}/${pathName}`,
      }),
    )

  return metaTags
}
